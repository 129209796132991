import React, { useState } from 'react'
import './SignUpPage.css'
import firebase from "firebase/app";
import { useHistory } from 'react-router-dom';
import signinImage from '../../assets/image/signin_image.svg'
import googleIcon from '../../assets/icon/google_icon.svg'


const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const history = useHistory();
    let db = firebase.firestore();
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    firebase.auth().useDeviceLanguage();

    const signUpNewUser = () => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                var user = userCredential.user;
                addUserToDb(user);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log("user error message", errorMessage)
                console.log("user error code", errorCode)
            });
    }

    const addUserToDb = (user) => {
        db.collection("Users").doc(user.uid).set({
            email: user.email,
            id: user.uid,
            lastname: lastname,
            firstname: firstname
        }).then((docRef) => {
            history.push('/home')
        })
            .catch(console.error);
    }

    const signUpGoogle = () => {
        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                // var credential = result.credential;
                // var token = credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                addUserToDb(user);
                let name = user.displayName.split(' ')
                setFirstname(name[0])
                setLastname(name[1])
            }).catch((error) => {
                history.push('/landing')
                var errorMessage = error.message;
                console.log("Google auth ERROR: ", errorMessage)
            });
    }

    return (
        <div className='sign'>
            <div className='sign__container-input'>
                <h1 className='sign--title'>Create your account</h1>
                <p>Already have an account? <a href='/signin'>Sign in</a></p>
                <button className='google-btn' onClick={() => signUpGoogle()}><img src={googleIcon} alt='googlesignin'/> Sign up with Google</button>
                <div className='custom-line'><span>Or</span></div>
                <div className='container-name'>
                    <input style={{width: '43%'}} className='sign-input' onChange={(e) => setFirstname(e.target.value)} type="text" placeholder="Firstname" />
                    <input style={{width: '43%'}} className='sign-input' onChange={(e) => setLastname(e.target.value)} type="text" placeholder="Lastname" />
                </div>
                <input className='sign-input' onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" />
                <input className='sign-input' onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                <button className='sign-button' onClick={signUpNewUser}>Sign up for free</button>
                <div>
                    <p style={{fontSize:'12px'}} className='terms-service'>By signing up, you agree to our<a href='/signup'> Terms of Service </a>and our <a href='/signup'> Privacy Policy</a></p>
                </div>
            </div>
            <div className='sign__container-image'>
                <img src={signinImage} alt="signupimg" />
            </div>
        </div>
    )
}

export default SignUpPage