import React, { useState } from 'react'
import { useEffect } from 'react';
import './SnippetComponent.css'
import deleteIcon from '../../assets/icon/delete_icon.svg'
import editIcon from '../../assets/icon/edit_icon.svg'


const SnippetComponent = ({ selectShortcut, shortcut, db, uid}) => {
    const [currentShortcut, setCurrentShortcut] = useState({ key: '', value: '' });
    useEffect(() => {
        if (shortcut) {
            setCurrentShortcut(shortcut)
        }
    }, [])

    const deleteShortcut = (shorcut) => {
        db.collection("Users").doc(uid).collection("shortcut").doc(shorcut.id).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }

    return (
        <div className="snippet" onClick={() => selectShortcut(currentShortcut)}>
            <div className='snippet-container'>
                <p className='value'>{currentShortcut.value}</p>
            </div>
            <p className='key'>{currentShortcut.key}</p>
            <button className='edit-button' style={{ height: "20px" }}>
                <img alt='edit' src={editIcon} />
            </button>
            <button className='delete-button' style={{ height: "20px", zIndex: 100 }} onClick={() => {
                deleteShortcut(shortcut)
            }}>
                <img alt='delete' src={deleteIcon} />
            </button>
        </div>
    )
}

export default SnippetComponent