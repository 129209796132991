import React from 'react'
import './RadioButtonComponent.css'


const RadioButtonComponent = ({selected}) => {
    return (
        <div className="radio-button">
            <div className={selected ? 'radio-button__active-part' : 'radio-button__notactive-part'}></div>
        </div>
    )
}

export default RadioButtonComponent