import React from 'react'
import './WelcomeComponent.css'
import welcomeGif from '../../assets/welcome.svg'
import blueArrow from '../../assets/blue_arrow.svg'


const WelcomeComponent = () => {
    return (
        <div className="welcome">
            <h1>Welcome on board!</h1>
            <p>It’s time to create your first snippet! You can write your most commun sentenses, or just some words like your email address, your phone number, the link to your calendar...</p>
            <p><strong>How I can insert my snippet?</strong></p>
            <p>You have two options to display your snippets: </p>
            <p>- Start typing the first 3 words of your sentence and you’ll see your snippet(s)! Just click on tab to expend your snippet!</p>
            <p>- Create a shortcut and just type your shortcut to expend your snippet. </p>
            <div className='welcome__gif'>
                <img alt='welcomegif' src={welcomeGif}/>
            </div>
            <img alt='bluearrow' className='blue_arrow' src={blueArrow}/>
        </div>
    )
}

export default WelcomeComponent