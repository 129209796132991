import React from 'react'
import RadioButtonComponent from '../RadioButtonComponent/RadioButtonComponent'
import './PlanChoiceComponent.css'


const PlanChoiceComponent = ({plan, discount, style, selected, click}) => {
    return (
        <div onClick={() => click(plan)} className={selected ? 'planchoice__active planchoice' : 'planchoice'} style={style}>
            <RadioButtonComponent selected={selected}/>
            <p>${plan.price} / {plan.rec} <span>{plan.message}</span></p>
            {
                discount ? <div className='planchoice__discount'>-{discount}%</div> : <></>
            }
        </div>
    )
}

export default PlanChoiceComponent