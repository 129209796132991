import React from 'react'
import './InstallPage.css'
import installImage from '../../assets/image/install_image.svg'
import logo from '../../assets/image/blue_logo.svg'
import chromeIcon from '../../assets/icon/chrome_icon.svg'
import { useHistory } from 'react-router'

const InstallPage = () => {
    const history = useHistory();
    const onClickAdd = () => {
        localStorage.setItem('typistyextension', true);
        history.push('/home')
    }
    return (
        <div className='sign'>
            <div className='sign__container-input'>
                <div style={{width: '100%', paddingTop: '2rem', paddingLeft: '2rem'}}>
                    <img src={logo} alt='blue logo'/>
                </div>
                <div className='sign__container-input'>
                    <h1 className='sign--title'>Install Chrome extension</h1>
                    <p>Type 10X faster without any efforts from your Chrome browser</p>
                    <a onClick={onClickAdd} href='https://chrome.google.com/webstore/detail/typisty-the-new-way-of-ty/kfnflaepongkoifomgnpjminhoganmai' target="_blank" rel="noreferrer"><button className='chrome-btn'><img style={{marginRight: '10px'}} src={chromeIcon} alt='chrome btn' />Add to Chrome</button></a>
                </div>
                <p className='install-skip' style={{paddingBottom: '1rem'}}>You can <span onClick={onClickAdd}>skip this step</span>, but you need install our Chrome Extension to use the product.</p>
            </div>
            <div className='sign__container-image'>
                <img src={installImage} alt="installImage" />
            </div>
        </div>
    )
}

export default InstallPage
