import React, { useEffect, useState } from 'react'
import './NavbarComponent.css'
import logoIcon from '../../assets/icon/logo_icon.svg'
import snippetIcon from '../../assets/icon/snippet_icon.svg'
import settingsIcon from '../../assets/icon/settings_icon.svg'
import cardIcon from '../../assets/icon/card_icon.svg'
import snippetBlueIcon from '../../assets/icon/snippet_blue_icon.svg'
import settingsBlueIcon from '../../assets/icon/settings_blue_icon.svg'
import cardBlueIcon from '../../assets/icon/card_blue_icon.svg'
import { NavbarKey } from '../../utils/enums';
import { useHistory, useLocation } from 'react-router-dom'
import SignOutComponent from '../SignOutComponent/SignOutComponent'


const NavbarComponent = () => {
    const [nodisplay, setNoDisplay] = useState(true);
    const [currentIcon, setCurrentIcon] = useState(1)
    let location = useLocation();
    const history = useHistory();
    useEffect(() => {
        getDefaultActiveKey(location.pathname)
    }, [location])

    const getDefaultActiveKey = (pathname) => {
        if (pathname.includes(NavbarKey.home)) {
            setNoDisplay(false)
            setCurrentIcon(1)
        } else if (pathname === '/') {
            setNoDisplay(true)
        } else if (pathname.includes(NavbarKey.settings)) {
            setNoDisplay(false)
            setCurrentIcon(2)
        } else if (pathname.includes(NavbarKey.pricing)) {
            setNoDisplay(false)
            setCurrentIcon(3)
        } else if (pathname.includes(NavbarKey.landing)) {
            setNoDisplay(true)
        }
        else if (pathname.includes(NavbarKey.signin)) {
            setNoDisplay(true)
        }
        else if (pathname.includes(NavbarKey.signup)) {
            setNoDisplay(true)
        }
        else if (pathname.includes(NavbarKey.install)) {
            setNoDisplay(true)
        }
    };

    const onNavTo = (route) => {
        history.push(route)
    }

    const isSelected = (nbrIcon) => {
        return (nbrIcon === currentIcon)
    }

    return (
        <>
            {
                nodisplay ? <></> : <div className="navbar">
                    <div className="logo">
                        <img alt='logo' src={logoIcon} />
                    </div>
                    {
                        isSelected(1) ?
                            <div className='navbar__icon_selected' onClick={() => onNavTo('/home')}>
                                <img alt='snippet' src={snippetBlueIcon} />
                            </div> :
                            <div className='navbar__icon' onClick={() => onNavTo('/home')}>
                                <img alt='snippet' src={snippetIcon} />
                            </div>
                    }
                    {
                        isSelected(2) ?
                            <div className='navbar__icon_selected' onClick={() => onNavTo('/settings')}>
                                <img alt='settings' src={settingsBlueIcon} />
                            </div> :
                            <div className='navbar__icon' onClick={() => onNavTo('/settings')}>
                                <img alt='settings' src={settingsIcon} />
                            </div>
                    }
                    {
                        isSelected(3) ?
                            <div className='navbar__icon_selected' onClick={() => onNavTo('/pricing')}>
                                <img alt='card' src={cardBlueIcon} />
                            </div> :
                            <div className='navbar__icon' onClick={() => onNavTo('/pricing')}>
                                <img alt='card' src={cardIcon} />
                            </div>
                    }
                    <SignOutComponent></SignOutComponent>
                </div>
            }
        </>
    )
}

export default NavbarComponent