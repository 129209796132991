import './App.css';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import NavbarComponent from './component/NavbarComponent/NavbarComponent';
import { useEffect, useState } from 'react';
import HomePage from './pages/HomePage/HomePage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import SignInPage from './pages/SignInPage/SignInPage';
import LandingPage from './pages/LandingPage/LandingPage';
import PricingPage from './pages/PricingPage/PricingPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import CheckoutSuccessPage from './pages/CheckoutSuccessPage/CheckoutSuccessPage';
// import NoMatchPage from './pages/NoMatchPage/NoMatchPage';
import InstallPage from './pages/InstallPage/InstallPage';


function initializeFirebase() {
  //test base
  // Rx2D
  // var firebaseConfig = {
  //   apiKey: "AIzaSyB_qAEa27bLrJXoDI-BKYMKxzVCLLj4iOU",
  //   authDomain: "r2xd-b8677.firebaseapp.com",
  //   databaseURL: "https://r2xd-b8677.firebaseio.com",
  //   projectId: "r2xd-b8677",
  //   storageBucket: "r2xd-b8677.appspot.com",
  //   messagingSenderId: "1071836667531",
  //   appId: "1:1071836667531:web:3fa0df18c2cfe8dcffbe8e",
  //   measurementId: "261184441",
  // };

  // R2xD-bis
  var firebaseConfig = {
    apiKey: "AIzaSyCQz7gXmHS-Ni4seYvIxhKvns04Rf1a8PM",
    authDomain: "rxd2-bis.firebaseapp.com",
    databaseURL: "https://rxd2-bis-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rxd2-bis",
    storageBucket: "rxd2-bis.appspot.com",
    messagingSenderId: "963141868171",
    appId: "1:963141868171:web:6f82b78ecf79f9ed96528e",
    measurementId: "G-1BCEFYE77N"
  };

  //initialize firebase
  firebase.initializeApp(firebaseConfig);
}

function onAuthStateChange(callback) {
  return firebase.auth().onAuthStateChanged(user => {
    if (user) {
      callback({ loggedIn: true });
    } else {
      callback({ loggedIn: false });
    }
  });
}

function App() {
  if (!firebase.apps.length) {
    initializeFirebase()
  }
  const [user, setUser] = useState({ loggedIn: false });
  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe()
    };
  }, []);

  return (
    <>
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <div style={{ display: 'flex', width: '100%' }}>
          {user.loggedIn ? <NavbarComponent></NavbarComponent> : <></>}
          {
            user.loggedIn ?
              <Switch>
                <Route exact path='/' component={LandingPage} />
                <Route exact path='/signin' component={SignInPage} />
                <Route exact path='/signup' component={SignUpPage} />
                <Route exact path='/home' component={HomePage} />
                <Route exact path='/settings' component={SettingsPage} />
                <Route exact path='/pricing' component={PricingPage} />
                <Route exact path="/home/:id" component={HomePage} />
                <Route path='/checkout/success' component={CheckoutSuccessPage} />
                {/* <Route component={NoMatchPage} /> */}
                <Route exact path='/install' component={InstallPage} />
                <Route component={LandingPage} />
              </Switch>
                :
                <Switch>
                  <Route exact path='/' component={LandingPage} />
                  <Route exact path='/signin' component={SignInPage} />
                  <Route exact path='/signup' component={SignUpPage} />
                  <Route exact path='/reset' component={ResetPasswordPage} />
                  {/* <Route component={NoMatchPage} /> */}
                  <Route component={LandingPage} />
              </Switch>
            }
        </div>
      </Router>
    </>
  );
}

export default App;
