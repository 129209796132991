import React, { useState } from 'react'
import { useEffect } from 'react';
import './EditSnippetComponent.css'
import firebase from "firebase/app";

const EditSnippetComponent = ({shortcut}) => {
    const [user, setUser] = useState(null);
    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    let db = firebase.firestore();
    useEffect(() => {
        var user = firebase.auth().currentUser;
        if (user) {
            setUser(user)
        }
    }, []);

    useEffect(() => {
        if (shortcut) {
            setKey(shortcut.key)
            setValue(shortcut.value)
        }
    }, [shortcut])

    const EditShortcut = (key, value, id) => {
        let shortcutRef = db.collection("Users").doc(user.uid).collection("shortcut").doc(id);
        // TODO CHANGER l'UPADATE (obliger d'update la key sinon pas d'update)
        shortcutRef.set({
            id, key, value
        });

        db.collection("Users").doc(user.uid).collection("shortcut").doc(id).update({
            key,
            value,
        }).then(() => {
            console.log("Document successfully updated!");
        }).catch((error) => {
            console.error("Error updating document: ", error);
        })
    }

    const checkLengthIsHigher = (value, length) => {
        if (value) {
            let tabvalue = []
            tabvalue = value.split(" ")
            if (tabvalue.length < length) {
                return false
            } else {
                return true
            }
        }
    }
    
    const checkAllValide = () => {
        if (key.length < 3 || !checkLengthIsHigher(value, 3)) {
            return false
        }
        return true
    }


    
    //todo ajouter des rules
    return (
        <div className="create-snip">
            <h1>Edit your snippet</h1>
            <p>Text snippet</p>
            <textarea
                name="valuesnippet"
                id="valuesnippet"
                cols="30"
                rows="10"
                value={value ? value : ''}
                placeholder='Write yout text'
                onChange={(e) => setValue(e.target.value)}
            ></textarea>
            {
                (!checkLengthIsHigher(value, 3) && value.length !== 0) && <span className='reminder-error-message'>3 words minimum required</span>
            }
            <p>Shortcut</p>
            <input
                type="text"
                name="keysnippet"
                id="keysnippet"
                value={key ? key : ''}
                placeholder='Choose a shorcut...'
                onChange={(e) => setKey(e.target.value)}
            />
            {
                (key.length < 3 && key.length !== 0) && <span className='reminder-error-message'>Shorcut is minimaume 3 caractere</span>
            }
            <button disabled={!checkAllValide()} onClick={() => EditShortcut(key, value, shortcut.id)} className='button-create-snippet'>Save changes</button>
        </div>
    )
}

export default EditSnippetComponent