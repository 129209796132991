import React, { useState } from 'react'
import './ResetPasswordPage.css'
import firebase from "firebase/app";
import { useHistory } from 'react-router-dom';
import resetImage from '../../assets/image/resetpsw_image.svg'


const ResetPasswordPage = () => {
    const [email, setEmail] = useState('');

    const history = useHistory();
    var auth = firebase.auth();

    const sendResetEmail = () => {
        console.log('email to reset: ', email)
        auth.sendPasswordResetEmail(email).then(function () {
            history.push('/signin')
            // Email sent.
        }).catch(function (error) {
            console.log('Error reset email:', error)
            // An error happened.
        });
    }

    return (
        <div className='sign'>
            <div className='sign__container-input'>
                <h1 className='sign--title'>Reset password</h1>
                <p>Don’t have an account? <a href='/signup'>Sign up</a></p>
                <div className='custom-line'><span>Or</span></div>
                <input className='sign-input' onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" />
                <button className='sign-button' onClick={sendResetEmail}>Sign up for free</button>
                <div>
                    <p style={{ fontSize: '12px' }}>By signing up, you agree to our<a href='/signup'> Terms of Service </a>and our <a href='/signup'> Privacy Policy</a></p>
                </div>
            </div>
            <div className='sign__container-image'>
                <img src={resetImage} alt="signin-i" />
            </div>
        </div>
    )
}

export default ResetPasswordPage