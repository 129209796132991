

import React, { useState } from 'react'
import { useEffect } from 'react';
import './CreateSnippetComponent.css'
import firebase from "firebase/app";

const CreateSnippetComponent = ({nbrShortcuts, isPaying}) => {
    const [user, setUser] = useState(null);
    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    const [nbrShortcut, setNbrShortcut] = useState(0);
    const [userPaying, setUserPaying] = useState(0);
    let db = firebase.firestore();
    useEffect(() => {
        var user = firebase.auth().currentUser;
        if (user) {
            setUser(user)
        }
    }, [user]);

    useEffect(() => {
        setNbrShortcut(nbrShortcuts)
    }, [nbrShortcuts])

    useEffect(() => {
        setUserPaying(isPaying)
    }, [isPaying])

    const addNewShortcut = (key, value) => {
        db.collection("Users").doc(user.uid).collection("shortcut").add({
            key,
            value
        })
            .then((docRef) => {
                setKey('')
                setValue('')
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }

    const checkLengthIsHigher = (value, length) => {
        if (value) {
            let tabvalue = []
            tabvalue = value.split(" ")
            if (tabvalue.length < length) {
                return false
            } else {
                return true
            }
        }
    }

    // const checkAbleCreateSnippet = () => {
    //     if (!userPaying && nbrShortcut >= 10) {
    //         return false
    //     } else {
    //         return true
    //     }
    // }

    const checkAllValide = () => {
        // checkAbleCreateSnippet()
        if (key.length < 3 || !checkLengthIsHigher(value, 3)) {
            return false
        }
        return true
    }

    //todo ajouter des rules
    return (
        <div className="create-snip">
            <h1>Create a new snippet</h1>
            <p>Text snippet</p>
            <textarea
                name="valuesnippet"
                id="valuesnippet"
                cols="30"
                rows="10"
                value={value}
                placeholder='Write yout text'
                onChange={(e) => setValue(e.target.value)}
            ></textarea>
            {
                (!checkLengthIsHigher(value, 3) && value.length !== 0) && <span className='reminder-error-message'>3 words minimum required</span>
            }
            <p>Shortcut</p>
            <input
                style={{marginBottom: '1rem'}}
                type="text"
                name="keysnippet"
                id="keysnippet"
                value={key}
                placeholder='Choose a shorcut...'
                onChange={(e) => setKey(e.target.value)}
            />
            {
                (key.length < 3 && key.length !== 0) && <span className='reminder-error-message'>Shorcut is minimaume 3 caractere</span>
            }
            <button disabled={!checkAllValide()} onClick={() => addNewShortcut(key, value)} className='button-create-snippet'>Save your snippet</button>
        </div>
    )
}

export default CreateSnippetComponent