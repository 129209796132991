import React from 'react'
import { useHistory } from 'react-router-dom';
import firebase from "firebase/app";
import logoutIcon from '../../assets/icon/logout_icon.svg'


const SignOutComponent = () => {
    const history = useHistory();

    const signOutUser = () => {
        firebase.auth().signOut().then(() => {
            history.push('/')
        }).catch((error) => {
            console.log('sign out error:', error)
        });
    }

    return (
        <>
            <div style={{marginTop: '67vh'}} className='navbar__icon' onClick={() => signOutUser()}>
                <img alt='door' src={logoutIcon} />
            </div>
        </>
    )
}

export default SignOutComponent