import React, { useEffect, useState } from 'react'
import './HomePage.css'
import AllSnippetsComponent from '../../component/AllSnippetsComponent/AllSnippetsComponent';
import CreateSnippetComponent from '../../component/CreateSnippetComponent/CreateSnippetComponent';
import EditSnippetComponent from '../../component/EditSnippetComponent/EditSnippetComponent';
import powerImage from '../../assets/image/power_image.svg'
import { useHistory } from 'react-router-dom';


const HomePage = () => {
    const [selectedShortcut, setSelectedShortcut] = useState('');
    const [nbrShortcuts, setNbrShortcuts] = useState('');
    const [isPaying, setIsPaying] = useState('');
    const history = useHistory();
    const selectShortcut = (shortcut) => {
        setSelectedShortcut(shortcut)
    }

    useEffect(() => {
        if (!localStorage.getItem('typistyextension')) {
            history.push('/install')
        }
    }, [])

    const openCreateSnippet = () => {
        setSelectedShortcut('')
    }

    const checkAbleCreateSnippet = () => {
        if (!isPaying && nbrShortcuts >= 10) {
            return false
        } else {
            return true
        }
    }

    const onClickHere = () => {
        history.push('/pricing')
    }

    return (
        <div className='home'>
            <div style={{ width: '60%' }}>
                <AllSnippetsComponent selectShortcut={selectShortcut} openCreateSnippet={openCreateSnippet} setNbrShortcuts={setNbrShortcuts} isPaying={setIsPaying} />
            </div>
            <div style={{ width: '40%', height: '100%', backgroundColor: 'white', position: 'relative' }} className='shadow-right'>
                {
                    // add component 
                    checkAbleCreateSnippet() ? <></> :
                    <div className='not-paying'>
                        <div className='not-paying__container'>
                            <div className='not-paying__elems'>
                                <h1>You’ve reached out your maximum number of free snippets</h1>
                                <span>If you want to unlock <span>unlimited snippets</span> upgrade your account</span>
                                <button className='sign-button' onClick={onClickHere}>Get full power</button>
                                <img src={powerImage} alt='power'/>
                            </div>
                        </div>
                    </div>
                }
                {
                    selectedShortcut ? <EditSnippetComponent shortcut={selectedShortcut} /> : <CreateSnippetComponent nbrShortcuts={nbrShortcuts} isPaying={isPaying} />
                }
            </div>
        </div>
    )
}

export default HomePage