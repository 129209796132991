import React, { useEffect, useState } from 'react'
import './SettingsPage.css'
import firebase from "firebase/app";

const SettingsPage = () => {
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [user, setUser] = useState(null);
    const [dbuser, setDbUser] = useState(null);
    firebase.auth().onAuthStateChanged(function (user) {
        setUser(user)
    });

    let db = firebase.firestore();
    var auth = firebase.auth();
    useEffect(() => {
        if (user) {
            setUser(user)
            const getUserStatus = (uid) => {
                let usersRef = db.collection("Users")
                usersRef.where("id", "==", uid).get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            setDbUser(doc.data())
                            setFirstname(doc.data().firstname)
                            setLastname(doc.data().lastname)
                            setEmail(doc.data().email)
                        });
                    })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                    });
            }
            getUserStatus(user.uid)
        }
    }, [user]);


    const resetPasswordByEmail = () => {
        auth.sendPasswordResetEmail(email).then(function () {
        }).catch(function (error) {
            console.log('error')
        });
    }

    const changeUserInformation = () => {
        var userRef = db.collection("Users").doc(user.uid);
        if (email !== user.email) {
            user.updateEmail(email).then(function () {
            }).catch(function (error) {
                console.log('Error', error)
            })
        }
        if (dbuser.firstname !== firstname || dbuser.lastname !== lastname || email !== user.email) {
            userRef.update({
                firstname: firstname,
                lastname: lastname,
                email: email
            })
                .then(() => {
                    console.log("Document successfully updated!");
                })
                .catch((error) => {
                    console.error("Error updating document: ", error);
                });
        }
    }

    return (
        <div className="settings">
            <h1>Account settings</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ marginRight: '3rem' }}>
                    <p>Firstname</p>
                    <input style={{ width: '100%' }} value={firstname} className='sign-details' onChange={(e) => setFirstname(e.target.value)} type="text" placeholder="Firstname" />
                </div>
                <div>
                    <p>Lastname</p>
                    <input style={{ width: '100%' }} value={lastname} className='sign-details' onChange={(e) => setLastname(e.target.value)} type="text" placeholder="Lastname" />
                </div>
            </div>
            <p>Email</p>
            <input className='sign-details' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" type="email" />
            <button className='sign-button' onClick={changeUserInformation}>Save page</button>
            <div className='reset-password-profil'>Reset your password? <span onClick={resetPasswordByEmail}>Click here</span></div>
        </div>
    )
}

export default SettingsPage