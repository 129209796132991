import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import firebase from "firebase/app";
import './SignInPage.css'
import signupImage from '../../assets/image/signup_image.svg'
import googleIcon from '../../assets/icon/google_icon.svg'

const SignInPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    let db = firebase.firestore();
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    firebase.auth().useDeviceLanguage();

    const signInNewUser = () => {
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                history.push('/home')
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log("error: ", errorMessage)
                console.log("errorcode: ", errorCode)
            });
    }

    const addUserToDb = (user) => {
        let name = user.displayName.split(' ')
        db.collection("Users").doc(user.uid).set({
            email: user.email,
            id: user.uid,
            firstname: name[0],
            lastname: name[1],
        }).then((docRef) => {
            history.push('/home')
        })
            .catch(console.error);
    }

    //todo revoir si on peux pas obtimiser les apelles
    const signInGoogle = () => {
        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                var user = result.user;
                addUserToDb(user);
            }).catch((error) => {
                history.push('/landing')
            });
    }

    return (
        <div className='sign'>
            <div className='sign__container-input'>
                <h1 className='sign--title'>Welcome back!</h1>
                <p>Don't have an account? <a href='/signup'>Sign up</a></p>
                <button onClick={() => signInGoogle()} className='google-btn'><img src={googleIcon} alt='googlesign'/> Sign in with Google</button>
                <div className='custom-line'><span>Or</span></div>
                <input className='sign-input' onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" />
                <input className='sign-input' onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                <button className='sign-button' onClick={signInNewUser}>Sign in to your account</button>
                <div>
                    <p style={{fontSize:'12px'}}>Forgot your password?<a href='/reset'> Click here.</a></p>
                </div>
            </div>
            <div className='sign__container-image'>
                <img src={signupImage} alt="signupimg" />
            </div>
        </div>
    )
}

export default SignInPage