import React, { useEffect, useState } from 'react'
import PlanChoiceComponent from '../../component/PlanChoiceComponent/PlanChoiceComponent'
import './PricingPage.css'
import firebase from "firebase/app";

import { loadStripe } from '@stripe/stripe-js';

const PricingPage = () => {
    const [selectedPlan, setSelectedPlan] = useState({ id: '1', price: '30', rec: 'years', message: 'billed annualy', priceId: 'price_1IUC4nEDuyo2b8u7FGg7AFpr', name: 'yearly_plan' });
    const [isSubscribe, setIsSubscribe] = useState(false);

    var user = firebase.auth().currentUser;
    let db = firebase.firestore();
    useEffect(()=>
        async function load(){
        if (user) {
            const userSnapshot = await db.collection("Users").doc(user.uid).get();
            const currentUser = userSnapshot.data();
            if (currentUser.webSubscription) {
                setIsSubscribe(true)
            }
        }
        load().then()
    }, [user])

    const onClickPlan = (plan) => {
        setSelectedPlan(plan)
    }

    const onClickValidated = async () => {
        var user = firebase.auth().currentUser;
        var createCheckoutSession = await firebase.functions().httpsCallable('createCheckoutSession');
        let test = await createCheckoutSession({ priceid: selectedPlan.priceId, uid: user.uid })
        //test stripe
        // const stripePromise = loadStripe("pk_test_Qe2s6LB6l2SxOTgz0V0MIez1");
        const stripePromise = loadStripe("pk_live_AAjbI0yvAp98xawMRW3XxAfy");
        const stripe = await stripePromise;
        if (test) {
            stripe.redirectToCheckout({
                sessionId: test.data.sessionId,
            })
                .then(() => {
                });
        }
    }

    const onClickManageBilling = async () => {
        var getStripePortalUrl = await firebase.functions().httpsCallable('getStripePortalUrl');
        let stripePortal = await getStripePortalUrl({ uid: user.uid })
        if (stripePortal) {
            window.location.href = stripePortal.data.portalurl
        }
    }

    return (
        <div className="pricing">
            <div style={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div className='princing-container'>
                    <h1>Choose your plan</h1>
                    <p className='text-pricing'>We like to keep it simple. <strong>Everything is unlimited</strong>. Cancel anytime.</p>
                    <PlanChoiceComponent click={onClickPlan} plan={{ id: '1', price: '30', rec: 'year', message: 'billed annualy', priceId: 'price_1IUC4nEDuyo2b8u7FGg7AFpr', name: 'yearly_plan' }} discount={'40'} selected={selectedPlan.id === '1'} />
                    <PlanChoiceComponent click={onClickPlan} plan={{ id: '2', price: '3,99', rec: 'month', message: 'billed monthly', priceId: 'price_1IUC4BEDuyo2b8u7WyasD1uf', name: 'monthly_plan' }} style={{ marginTop: '1.5rem' }} selected={selectedPlan.id === '2'} />
                    {
                        isSubscribe ? <button className='blue_button' onClick={() => onClickManageBilling()}>Manage subscription</button> :
                            <button className='blue_button' onClick={() => onClickValidated()}>Start subscription</button>
                    }
                </div>
            </div>
            <p className='contact-us'>Please contact us for <a href='mailto:hello@typisty.com'>biling question.</a></p>
        </div>
    )
}

export default PricingPage
