import React from 'react'
import { useHistory } from 'react-router-dom';
import firebase from "firebase/app";
import './LandingPage.css'
import blueLogo from '../../assets/image/blue_logo.svg' 
import loading from '../../assets/icon/loading.svg' 


const LandingPage = () => {
    const history = useHistory();
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            onRedirectTo('/home')
        } else {
            onRedirectTo('/signin')
        }
    });

    const onRedirectTo = (link) => {
        history.push(link)
    }

    return (
        <div className='landing'>
            <img src={blueLogo} alt='blueLogo'/>
            <div className='landing-container'>
                <h1>This is the new way of typing.</h1>
                <img alt='loading' className='rotating' src={loading}/>
            </div>
        </div>
    )
}

export default LandingPage