import React, { useState } from 'react'
import { useEffect } from 'react';
import './AllSnippetsComponent.css'
import firebase from "firebase/app";
import SnippetComponent from '../SnippetComponent/SnippetComponent';
import WelcomeComponent from '../WelcomeComponent/WelcomeComponent';
import addIcon from '../../assets/icon/add_icon.svg'
import { useParams } from 'react-router-dom';


const AllSnippetsComponent = ({ selectShortcut, openCreateSnippet, setNbrShortcuts, isPaying }) => {
    const [shortcuts, setShortcuts] = useState([]);
    const [user, setUser] = useState(null);
    let params = useParams();
    firebase.auth().onAuthStateChanged(function (user) {
        setUser(user)
    });

    //todo trouver solution pour eviter double db
    //recheck le useEffect avec le user pas sur du tous creer un chargement
    let db = firebase.firestore();
    useEffect(() => {
        let db = firebase.firestore();
        if (user) {
            setUser(user)
            const getAllShortcut = (uid) => {
                let shortcutsRef = db.collection("Users").doc(uid).collection("shortcut")
                shortcutsRef.orderBy("key").onSnapshot((querySnapshot) => {
                    let shortcutList = []
                    querySnapshot.forEach((doc) => {
                        shortcutList.push({ id: doc.id, key: doc.data().key, value: doc.data().value })
                    });
                    if (!shortcuts.length) {
                        openCreateSnippet()
                    }
                    setNbrShortcuts(shortcutList.length)
                    if (params.id) {
                        let editshortcut = shortcutList.find(e => e.id === params.id)
                        selectShortcut(editshortcut)
                    }
                    setShortcuts(shortcutList, db)
                })
            }
            getAllShortcut(user.uid)


            const getUserStatus = (uid) => {
                let usersRef = db.collection("Users")
                usersRef.where("id", "==", uid).get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            if (doc.data().status) {
                                if (doc.data().status.plan === "free") {
                                    isPaying(false)
                                    return
                                } else {
                                    isPaying(true)
                                    return
                                }
                            } else {
                                isPaying(false)
                                return
                            }
                        });
                    })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                });
            }
            getUserStatus(user.uid)
        }
    }, [user]);

    return (
        <div className="all-snip">
            {
                shortcuts.length ?
                    <>
                        <div className="all-snip__top-container">
                            <div className='all-snip__title-container'>
                                <h1>Snippets</h1>
                                <span>{shortcuts.length}</span>
                            </div>
                            <button className='create-button' onClick={() => openCreateSnippet()}><img alt='open create' style={{ marginRight: '0.4rem', opacity: '0.3' }} src={addIcon} />Create a snippet</button>
                        </div>
                        <div>
                            {
                                shortcuts.map((shortcut, index) =>
                                    <div key={shortcut.key + index + shortcut.value}>
                                        <SnippetComponent selectShortcut={selectShortcut} shortcut={shortcut} db={db} uid={user.uid} />
                                    </div>
                                )
                            }
                        </div>
                    </> : <WelcomeComponent />
            }
        </div>
    )
}

export default AllSnippetsComponent