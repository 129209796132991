import React, { useState } from 'react'
import './CheckoutSuccessPage.css'
import { useHistory } from 'react-router-dom'
import checkoutImage from '../../assets/image/checkout_image.svg'
import validateIcon from '../../assets/icon/validate_icon.svg'
import { useEffect } from 'react'
import firebase from "firebase/app";


const CheckoutSuccessPage = () => {
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id")
    const [user, setUser] = useState(null);
    firebase.auth().onAuthStateChanged(function (user) {
        setUser(user)
    });
    useEffect(() => {
        if (user) {
            if (sessionId) {
                var getCheckoutSession = firebase.functions().httpsCallable('checkoutSession');
                getCheckoutSession({
                    sessionId: sessionId
                }).then((result) => {
                })
            }
        }
    }, [user])
    return (
        <>
            <div className='sign'>
                <div className='sign__container-input'>
                    <img src={validateIcon} alt="validate icon" />
                    <h1 className='sign--title'>Payment completed!</h1>
                    <p style={{ width: '60%', textAlign: 'center' }}>Thanks for beeing a part of our customer! Your plan was correctly applied on your account.</p>
                    <button className='sign-button' onClick={() => history.push('/home')}>Back to your dashboard</button>
                </div>
                <div className='sign__container-image'>
                    <img src={checkoutImage} alt="checkout-i" />
                </div>
            </div>
        </>
    )
}

export default CheckoutSuccessPage